import { Box, Container } from '@material-ui/core';
import React from 'react';
import logo from '../images/logo.svg';
const FourOhFour = () => {
	return (
		<Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" flexDirection="column">
			<Box style={{ fontSize: 60, fontWeight: 600, color: 'var(--color-primary)' }} display="flex" alignItems="center">
				<span>4</span>
				<img src={logo} style={{ margin: 10 }} height={50} alt="logo" />
				<span>4</span>
			</Box>
			<h2 style={{ fontFamily: 'sans-serif', fontWeight: 400 }}>This page doesn't exist.</h2>
		</Box>
	);
};

export default FourOhFour;
