import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

function PlainMarkdown({ question, style }) {
	const components = {
		code({ node, inline, className, children, ...props }) {
			const match = /language-(\w+)/.exec(className || 'language-console');
			return !inline && match ? (
				<SyntaxHighlighter style={prism} language={match[1]} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
			) : (
				<code className={className} {...props}>
					{children}
				</code>
			);
		},
	};

	return (
		<div style={style}>
			<ReactMarkdown className="markdown-body" components={components} remarkPlugins={[gfm]} children={question.question} />
		</div>
	);
}

export default PlainMarkdown;
