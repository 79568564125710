import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
	},
	TextField: {
		marginTop: theme.spacing(1),
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main,
			borderWidth: 2,
		},

		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main,
			borderWidth: 2,
		},
		// '&:hover .MuiOutlinedInput-input': {
		// 	color: 'purple',
		// },

		'&:hover .MuiInputLabel-outlined': {
			color: theme.palette.secondary.main,
		},
	},
}));

export default useStyles;
