import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import palette from '../themes/palette';
import { contrastColor } from 'contrast-color';

const StyledButton = ({ type, className, style, endIcon, color = 'primary', variant, children, onClick, disabled = false }) => {
	const CustomizedButton = withStyles((theme) => ({
		root: {
			color: contrastColor({ bgColor: theme.palette[color]?.main || theme.palette.primary.main, threshold: 190 }),
			// color: theme.palette.getContrastText({ background: theme.palette[color]?.main || theme.palette.primary.main }),
			backgroundColor: theme.palette[color]?.main || theme.palette.primary.main,
			'&:hover': {
				backgroundColor: theme.palette[color].dark || theme.palette.primary.dark,
			},
		},
	}))(Button);
	return (
		// <ThemeProvider theme={theme}>
		<CustomizedButton onClick={onClick} type={type} style={style} className={className} variant={variant} endIcon={endIcon} disabled={disabled}>
			{children}
		</CustomizedButton>
		// </ThemeProvider>
	);
};

export default StyledButton;
