import { Box } from '@material-ui/core';
import React from 'react';
import logo from '../images/logo.svg';
const TitleSection = ({ className, title }) => {
	return (
		<Box className={className} style={{ position: 'relative' }} bgcolor="white" boxShadow={1} borderRadius={10} padding={3}>
			<img style={{ position: 'absolute', top: '10px', right: '10px' }} src={logo} alt="logo" width={40} />
			<h1>{title ? title : 'CoderSchool Feedback Form'}</h1>
			<p>We appreciate your feedbacks and every feedback counts! We care about you and will improve from your feedback! It should takes only a few minutes to finish the form!</p>
		</Box>
	);
};

export default TitleSection;
