import { Box } from '@material-ui/core';
import React from 'react';
import logo from '../images/logo.svg';
const Error = ({ message }) => {
	return (
		<Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" flexDirection="column">
			<Box style={{ fontSize: 60, fontWeight: 600, color: 'var(--color-primary)' }} display="flex" alignItems="center">
				<span>ERR</span>
				<img src={logo} style={{ margin: 10 }} height={50} alt="logo" />
				<span>R</span>
			</Box>
			<h2 style={{ fontFamily: 'sans-serif', fontWeight: 400 }}>{message}</h2>
		</Box>
	);
};

export default Error;
