/* eslint-disable import/no-anonymous-default-export */
export default {
	palette: {
		calmBlue: '#2ec4b6',
		lightBlue: '#cbf3f0',
		okayGreen: '#a7c957',
		coderschoolRed: '#d2413e',
	},
	accent: {
		mustardYellow1: '#F0CE12',
		mustardYellow2: '#FFF5BF',
		apricotOrange1: '#FFA92B',
		apricotOrange2: '#FFF6E9',
		rubyRed1: '#F64066',
		rubyRed2: '#FEEBEF',
		viridianGreen1: '#1CB69A',
		viridianGreen2: '#E7F8F5',
	},
	gray: {
		textGray: '#2E2E2E',
		textGray1: '#414141',
		textGray2: '#74798C',
		disableGray: '#C7C7C7',
		disableGray1: '#EAEAEA',
		disableGray2: '#F4F4F4',
		background: '#FDFDFF',
		darkGray: '#414141',
	},
};
