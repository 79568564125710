import { Box, Container } from '@material-ui/core';
import React from 'react';

const ThankYou = () => {
	return (
		<Container style={{ flexGrow: 1, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
			<Box textAlign="center">
				<h1>We value every feedback!</h1>
				<p>Thank you for taking your time to complete this form.</p>
			</Box>
		</Container>
	);
};

export default ThankYou;
