import colors from './colors';
import palette from './palette';

const overrides = {
	MuiFormLabel: { root: { fontSize: 14 } },

	MuiInputBase: {
		input: {
			fontSize: 14,

			'&::placeholder': {
				fontSize: 14,
			},
		},
	},
	MuiFormHelperText: {
		root: {
			color: palette.error.main + '!important',
			// height: 0,
			marginBottom: '-20px!important',
			letterSpacing: 0,
		},
	},
};

export default overrides;
