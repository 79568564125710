import { Box } from '@material-ui/core';
import { FastField, useField } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import useStyles from '../mui/styles';
import PlainMarkdown from './PlainMarkdown';

const EmailSection = ({ name }) => {
	const classes = useStyles();
	const [field, meta] = useField(name);
	let configProps = {
		...field,
	};

	if (meta && meta.touched && meta.error) {
		configProps.error = true;
		configProps.helperText = meta.error;
	}
	return (
		<Box className={classes.formControl} bgcolor="white" boxShadow={1} borderRadius={10} padding={3}>
			<PlainMarkdown question={question} />

			<Box maxWidth={400}>
				<FastField component={TextField} className={classes.TextField} margin="normal" fullWidth size="small" id="email" label="Email Address" name="email" autoComplete="email" type="email" />
			</Box>
		</Box>
	);
};

export default EmailSection;

const question = {
	question: `#### Email Address`,
};
