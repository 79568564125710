import { Typography } from '@material-ui/core';
import React from 'react';

function Copyright({ className }) {
	return (
		<Typography variant="body2" color="textSecondary" align="center" className={className}>
			{'Copyright © '}
			<a style={{ color: 'inherit' }} href="https://www.coderschool.vn/">
				CoderSchool
			</a>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default Copyright;
