import { Box, Container } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import EmailSection from '../components/EmailSection';
import QuestionSection from '../components/QuestionSection';
import StyledButton from '../components/StyledButton';
import TitleSection from '../components/TitleSection';
import useStyles from '../mui/styles';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';
import ThankYou from './ThankYou';
import { toast } from 'react-toastify';
import Error from '../components/Error';

function Feedback() {
	const { id } = useParams();
	const [assignment, setAssignment] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [showPage, setShowPage] = useState('loading');
	const classes = useStyles();
	const getData = async () => {
		try {
			const url = process.env.REACT_APP_PROD_SERVER + `/assignments/${id}`;
			const res = await fetch(url);
			const data = await res.json();
			setAssignment(data.data);
			if (res.status !== 200 && data.message) {
				console.log(data);
				toast.error(data.message);
				setError(data.message);
			}
		} catch (err) {
			setError(err?.message || 'Something happened.');
			// toast.error(err);
		}
	};

	const handleSubmit = async (values, actions) => {
		try {
			setLoading(true);
			let answers = [];
			assignment.questions.forEach((question, index) => answers.push({ question: question.question, answer: values.answers[index] }));
			const body = { email: values.email, answers, assignmentId: id };
			// if (!body.email) delete body.email;
			const url = process.env.REACT_APP_PROD_SERVER + `/submissions`;
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			});
			const data = await res.json();
			setLoading(false);
			if (data.statusCode === 'SUCCESS') setShowPage('feedback-exit');
			else toast.error(data?.message || 'Something happened. Please contact the admin.');
			actions.setSubmitting(false);
		} catch (err) {
			setLoading(false);
			toast.error(err);
			actions.setSubmitting(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);
	if (error) return <Error message={error} />;
	return (
		<Box flexGrow={1} display="flex" flexDirection="column">
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 5,
					width: '100vw',
					height: '100vh',
					backgroundColor: 'rgba(0, 0, 0, 0.55)',
					display: loading ? 'flex' : 'none',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					color: 'white',
				}}
			>
				<Loader type="Oval" color="white" height={50} width={50} />
				<p>Submitting</p>
			</div>
			<CSSTransition in={assignment === null} timeout={{ appear: 1000, exit: 500 }} onExited={() => setShowPage('feedback')} classNames="loading-screen" appear unmountOnExit>
				<div className="page-center">
					<Loader type="Oval" color="#dd7171" height={50} width={50} />
				</div>
			</CSSTransition>
			<CSSTransition in={showPage === 'feedback'} timeout={{ enter: 1000, exit: 1000 }} classNames="feedback-form" mountOnEnter unmountOnExit onExited={() => setShowPage('thank-you')}>
				<Container component="main" maxWidth="md">
					<TitleSection className={classes.formControl} title={assignment?.name} />
					<Formik initialValues={{ email: '', answers: Array(assignment?.questions?.length).fill('') }} validationSchema={validationSchema} onSubmit={handleSubmit}>
						<Form>
							<EmailSection name="email" className={classes.formControl} />
							{assignment?.questions.map((item, index) => (
								<QuestionSection className={classes.formControl} key={index} index={index} question={item} />
							))}
							<Box display="flex" justifyContent="flex-end" className={classes.formControl}>
								<StyledButton disabled={loading} variant="contained" type="submit" color="primary">
									Submit
								</StyledButton>
							</Box>
						</Form>
					</Formik>
				</Container>
			</CSSTransition>
			<CSSTransition in={showPage === 'thank-you'} timeout={{ enter: 1000 }} classNames="thank-you" mountOnEnter>
				<ThankYou />
			</CSSTransition>
		</Box>
	);
}

export default Feedback;

const validationSchema = Yup.object().shape({
	email: Yup.string().email().required('Please fill in your email address.'),
	answers: Yup.array().of(Yup.string().required('This field is required.')).required(),
});
