import colors from './colors';
// import Color from 'color';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	primary: {
		main: colors.palette.coderschoolRed,
		// light: Color(colors.palette.coderschoolRed).lighten(0.3).hex(),
		// dark: Color(colors.palette.coderschoolRed).darken(0.3).hex(),
	},
	secondary: {
		main: colors.palette.calmBlue,
		// light: Color(colors.palette.calmBlue).lighten(0.3).hex(),
		// dark: Color(colors.palette.calmBlue).darken(0.3).hex(),
	},
	error: {
		main: colors.accent.rubyRed1,
		// light: colors.accent.rubyRed2,
	},
	success: {
		main: colors.palette.okayGreen,
		// light: colors.accent.viridianGreen2,
	},
	warning: {
		main: colors.accent.mustardYellow1,
		// light: colors.accent.mustardYellow2,
	},
	textPrimary: {
		main: colors.palette.black,
	},
	contrastThreshold: 2,
	tonalOffset: 0.1,
};
