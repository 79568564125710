import React, { useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, withStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';

function MultichoiceMany({ style, index, question, name }) {
	const [checkBoxes, setCheckBoxes] = useState([]);
	const { setFieldValue } = useFormikContext();
	const handleChange = (e) => {
		let newValues = [...checkBoxes];
		if (checkBoxes.includes(e.target.value)) {
			newValues.splice(
				checkBoxes.findIndex((item) => item === e.target.value),
				1
			);
		} else {
			newValues.push(e.target.value);
		}
		newValues.sort();
		setCheckBoxes(newValues);
		const userChoice = newValues.join(',');
		setFieldValue(name, userChoice);
	};
	return (
		<Box style={style}>
			<FormControl component="fieldset">
				<FormGroup row aria-label="answer">
					{question.choices.map((choice, i) => (
						<FormControlLabel value={choice} key={`choice_${i}`} control={<BlueCheckbox style={{ padding: 0 }} />} label={choice} labelPlacement="top" onChange={handleChange} />
					))}
				</FormGroup>
			</FormControl>
		</Box>
	);
}

export default MultichoiceMany;

const BlueCheckbox = withStyles({
	root: {
		'&$checked': {
			color: '#0075ff',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);
