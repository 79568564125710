import { createTheme } from '@material-ui/core/styles';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';

const theme = createTheme({
	typography,
	palette,
	overrides,
});

export default theme;
