import { Box } from '@material-ui/core';
import React from 'react';
import PlainMarkdown from './PlainMarkdown';
import UserInput from './UserInput';

const QuestionSection = ({ question, className, index }) => {
	return (
		<Box className={className} bgcolor="white" boxShadow={1} borderRadius={10} padding={4}>
			<PlainMarkdown question={question} />
			<UserInput question={question} index={index} />
		</Box>
	);
};

export default QuestionSection;
