import { Box, useMediaQuery, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../mui/styles';
import { useField, FastField } from 'formik';
import { TextField } from 'formik-material-ui';
import MultichoiceMany from './MultichoiceMany';

const UserInput = ({ question, index }) => {
	const theme = useTheme();
	const isMedium = useMediaQuery(theme.breakpoints.down('md'));

	const name = `answers.${index}`;
	const [field, meta] = useField(name);
	const classes = useStyles();
	// const [value, setValue] = useState('');
	let configProps = {
		...field,
	};

	if (meta && meta.touched && meta.error) {
		configProps.error = true;
		configProps.helperText = meta.error;
	}
	switch (question.resultType) {
		case 'VALUE':
			return <FastField component={TextField} multiline fullWidth {...configProps} />;
		case 'MULTICHOICE_SINGLE':
			return (
				<>
					<Box className={classes.formControl} width="100%">
						{question.choices.length > 9 ? (
							<Box display="flex" alignItems="center" marginX={3}>
								<Box display={{ xs: 'none', md: 'block' }}>Bad</Box>
								<Box display="flex" justifyContent="center" flexGrow={1} flexWrap="wrap" marginX={isMedium ? 0 : 3}>
									{question.choices.map((choice, index) => (
										<Box textAlign="center" marginX={isMedium ? 1 : 2} key={`radio_${index}`}>
											<div>{choice}</div>
											<FastField type="radio" name={name} value={choice} key={`choice_${index}`} />
										</Box>
									))}
								</Box>
								<Box display={{ xs: 'none', md: 'block' }}>Good</Box>
							</Box>
						) : (
							<Box display="flex" flexGrow={1} flexWrap="wrap">
								{question.choices.map((choice, index) => (
									<Box textAlign="center" marginX={isMedium ? 1 : 2} key={`radio_${index}`}>
										<div>{choice}</div>
										<FastField type="radio" name={name} value={choice} key={`choice_${index}`} />
									</Box>
								))}
							</Box>
						)}
					</Box>
					<Box style={{ marginTop: '-5px' }}>
						{configProps.error ? (
							<FormHelperText error id={name}>
								{configProps.helperText}
							</FormHelperText>
						) : null}
					</Box>
				</>
			);
		case 'MULTICHOICE_MANY':
			return (
				<>
					<MultichoiceMany question={question} index={index} name={name} />
					<Box style={{ marginTop: '-5px' }}>
						{configProps.error ? (
							<FormHelperText error id={name}>
								{configProps.helperText}
							</FormHelperText>
						) : null}
					</Box>
				</>
			);
		default:
			return null;
	}
};

export default UserInput;
