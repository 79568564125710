import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Route, Switch } from 'react-router-dom';
import Feedback from './pages/Feedback';
import AlertMsg from './components/AlertMsg';
import Copyright from './components/Copyright';
import useStyles from './mui/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FourOhFour from './pages/404';
function App() {
	const classes = useStyles();
	return (
		<Scrollbars style={{ width: '100vw', height: '100vh' }} autoHide autoHideTimeout={3000}>
			<div className="App">
				<AlertMsg />
				<Switch>
					<Route exact path="/:id" component={Feedback} />
					<Route component={FourOhFour} />
				</Switch>
				<Copyright className={classes.formControl} />
			</div>
		</Scrollbars>
	);
}

export default App;
